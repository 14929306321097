
  export default {
    props: {
      block: {
        type: Object,
        default: () => [],
        required: false,
      },
    },
    mounted() {
      try {
        if (this.$route.query.error) {
          const errorDiv = this.$refs.container.querySelector('#pageMessage');
          errorDiv.innerText = decodeURIComponent(this.$route.query.error);
          errorDiv.className += ' text-danger';
        }
      } catch (e) {}
    },
  };
